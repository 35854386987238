import React, {useState, useRef} from 'react';
import {MenuItem} from 'src/design-system';
import PaperMenu from 'src/design-system/paper-menu';

interface Props {
  anchor: JSX.Element;
  options: any[];
}

const Menu = ({anchor, options}: Props) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef();

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  return (
    <PaperMenu
      visible={visible}
      onDismiss={closeMenu}
      anchor={React.cloneElement(anchor, {onPress: openMenu})}
      ref={ref}>
      {options.map(({title, icon, svg = false, param, onPress}: any) => (
        <MenuItem
          key={`menu-key-${title}-${icon}`}
          title={title}
          icon={icon}
          svg={svg}
          onPress={() => {
            closeMenu();
            onPress(param);
          }}
        />
      ))}
    </PaperMenu>
  );
};

export default Menu;
