import React from 'react';
import PayerForm from 'src/modules/billing/components/payer-form';
import PayerPlansForm, {
  defaultPlan,
} from 'src/modules/billing/components/payer-plan-form';
import Review from 'src/modules/billing/components/payer-review';
import {useNavigation} from '@react-navigation/native';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import {IDeveloperField, IRowHook} from 'dromo-uploader-react';
import {Import as PayerImport, Value} from 'src/hook-form-inputs/payer';
import {Payer, PayerPlans, PayerCredential} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import PayerStaffForm from 'src/modules/billing/components/payer-staff-form';
import _ from 'lodash';
import StepForm from 'src/hook-form/step-form';
import * as Yup from 'yup';

export const Import: (...args: any) => IDeveloperField[] = () => [
  PayerImport('payer'),
];

export const RowHooks: IRowHook[] = [
  record => {
    return record;
  },
];

const PayerFormScreen = ({id, payer, payerCredentials, plans = []}: any) => {
  const database = useDatabase();
  const navigation = useNavigation();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const validationSchema = Yup.object({
    payer: Yup.number().required().typeError('Please Select a Payer'),
  });

  const navigateBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Billing', {screen: 'Insurance'});
    }
  };

  const onSubmit = async (values: any) => {
    const payerValue = Value(values.payer);

    if (id) {
      await payer.updateEntity({
        name: payerValue?.label,
        payerId: `${payerValue?.id}`,
        inovalonId: payerValue?.value,
        legacyId: values?.legacyId,
        enrollment: payerValue?.enrollment,
        address: values?.address,
        mobilePhone: values?.mobilePhone,
        staffIds: values?.staffIds,
      });

      const credentials = await payer.credentials.fetch();
      const deleteCredentials = _.differenceWith(
        payerCredentials,
        values.assignedCredentials,
        (value1: any, value2: any) => {
          return value1.credentialId === value2.id;
        },
      );
      const differenceUsers: string[] = _.differenceWith(
        values.assignedCredentials,
        payerCredentials,
        (value1: any, value2: any) => {
          return value1.id === value2.credentialId;
        },
      );

      for (const value1 of credentials) {
        const index = values.assignedCredentials.findIndex(
          (value2: any) => value1.credentialId === value2.id,
        );
        if (index !== -1) {
          await value1.updateEntity({
            credentialId: values?.assignedCredentials[index].id,
            legacyId: values?.assignedCredentials[index].legacyId,
          });
        }
      }
      for (const payerCredential of deleteCredentials) {
        payerCredential.delete();
      }
      for (const payerCredential of differenceUsers) {
        await database.write(async () => {
          return database?.get(PayerCredential.table).create(entity => {
            entity.partition = selectedGroup;
            entity.credentialId = payerCredential.id;
            entity.legacyId = payerCredential.legacyId;
            entity.payerId = id;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        });
      }

      for (const plan of values.plans) {
        if (plan?._id) {
          const entity = await database.get(PayerPlans.table).find(plan._id);

          await entity.updateEntity({
            name: plan.name,
            feeSchedule: plan.feeSchedule,
            deletedAt: plan?.deletedAt ?? null,
          });
        } else {
          await database?.write(async () => {
            return database?.get(PayerPlans.table).create(entity => {
              entity.partition = selectedGroup;
              entity.name = plan.name;
              entity.payerId = id;
              entity.feeSchedule = plan.feeSchedule;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
      }
    } else {
      const createdInsurance = await database?.write(async () => {
        return database?.get(Payer.table).create(entity => {
          entity.partition = selectedGroup;
          entity.name = payerValue?.label;
          entity.payerId = `${payerValue?.id}`;
          entity.inovalonId = payerValue?.value;
          entity.legacyId = values?.legacyId;
          entity.enrollment = payerValue?.enrollment;
          entity.address = values?.address;
          entity.mobilePhone = values?.mobilePhone;
          entity.staffIds = values?.staffIds;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });

      for (const credential of values.assignedCredentials) {
        await database?.write(async () => {
          return database?.get(PayerCredential.table).create(entity => {
            entity.partition = selectedGroup;
            entity.credentialId = credential.id;
            entity.legacyId = credential.legacyId;
            entity.payerId = createdInsurance.id;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        });
      }

      for (const plan of values.plans) {
        await database?.write(async () => {
          return database?.get(PayerPlans.table).create(entity => {
            entity.partition = selectedGroup;
            entity.name = plan.name;
            entity.payerId = createdInsurance.id;
            entity.feeSchedule = plan.feeSchedule;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        });
      }
    }
    navigateBack();
  };

  return (
    <StepForm
      onSubmit={onSubmit}
      defaultValues={{
        payer: payer.inovalonId || '',
        address: payer.address ?? {
          use: '',
          line: '',
          city: '',
          state: '',
          postalCode: '',
          country: 'US',
        },
        legacyId: payer.legacyId || '',
        mobilePhone: payer.mobilePhone || '',
        staffIds: payer.staffIds,
        plans: plans.length
          ? plans.map((entity: any) => ({
              _id: entity.id,
              name: entity.name,
              feeSchedule: entity.feeSchedule,
            }))
          : [defaultPlan],
        credentials: payerCredentials.map((entity: any) => entity.credentialId),
        assignedCredentials: payerCredentials.map((entity: any) => ({
          _id: entity.id,
          id: entity.credentialId,
          legacyId: entity.legacyId || '',
        })),
      }}
      defaultSteps={[
        {
          step: 'Payer',
          fields: ['payer'],
          form: PayerForm,
          validated: true,
          touched: false,
        },
        {
          step: 'Plans',
          fields: [],
          form: PayerPlansForm,
          validated: true,
          touched: false,
        },
        {
          step: 'Assigned Staff',
          fields: [],
          form: PayerStaffForm,
          validated: true,
          touched: false,
        },
        {
          step: 'Review',
          fields: [],
          form: Review,
          validated: true,
          touched: false,
        },
      ]}
      validationSchema={validationSchema}
      navigateBack={navigateBack}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['route'], ({route, database}: any) => {
    if (route.params?.id) {
      return {
        id: of(route.params?.id),
        payer: database.get(Payer.table).findAndObserve(route.params.id),
      };
    }
    return {
      id: of(route.params?.id),
      payer: of({
        payer: '',
        roundingRules: 8,
        timeFormat: false,
      }),
    };
  }),
  withObservables(['payer'], ({payer}) => {
    if (!payer?.id) {
      return {
        payerCredentials: of([]),
      };
    }
    return {
      payerCredentials: payer.activeCredentials,
      plans: payer.activePlans,
    };
  }),
)(PayerFormScreen);
