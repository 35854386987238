import React, {useState} from 'react';
import {Searchbar, Snackbar} from 'react-native-paper';
import {BaseIndexScreen, DeleteModal} from 'src/design-system';
import CsvExport from 'src/common-components/csv-export/components';
import {Import, PatientList, RowHooks} from 'src/modules/patients/components';
import {useNavigation} from '@react-navigation/native';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import DromoImportModal from 'src/common-components/bulk-import';
import {useSelector} from 'react-redux';
import {of} from 'rxjs';
import moment from 'moment';
import _ from 'lodash';
import {Patient, Caregiver} from 'src/models';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import ClientFilterMenu from '../../components/client-filter';
import ClientFilterChip from '../../components/client-filter-chip';

const PatientListScreen = ({users, patients, profile, role, tags}: any) => {
  const database = useDatabase();
  const navigation = useNavigation();
  const [searchFilter, setSearchFilter] = useState('');
  const [showDelete, setShowDelete] = useState<any | boolean>(false);
  const [createdPatients, setCreatedPatients] = useState<any[]>([]);

  const {selectedGroup} = useSelector(state => state.authentication);
  const [filters, setFilters] = useState<any>({
    patientState: [],
    relatedServices: [],
  });
  const exportPatients = async () => {
    let _exportPatients = [
      [
        'Identifier',
        'First Name',
        'Last Name',
        'Gender',
        'Birth Date',
        'Address Type',
        'Street Address',
        'City',
        'State',
        'Postal Code',
        'Caregiver First Name',
        'Caregiver Last Name',
        'Caregiver Phone Number',
        'Caregiver Email',
        'Caregiver Relationship',
        'Intake Date',
        'Staff',
      ],
    ];
    for (const patient of patients) {
      const practitioner = await patient.staff;
      const caregiver = patient.caregiver
        ? await patient.caregiver.fetch()
        : null;
      const diagnoses = Array.isArray(patient.diagnoses)
        ? patient.diagnoses.join(',')
        : '';
      _exportPatients.push([
        patient?.identifier || '',
        patient?.firstName || '',
        patient?.lastName || '',
        _.capitalize(patient?.gender) || '',
        moment(patient?.birthDate).format('MM/DD/YYYY') || '',
        _.capitalize(patient?.address?.use) || '',
        patient?.address?.line?.[0] || '',
        patient?.address?.city || '',
        patient?.address?.state || '',
        patient?.address?.postalCode || '',
        caregiver?.firstName || '',
        caregiver?.lastName || '',
        caregiver?.mobilePhone || '',
        caregiver?.email || '',
        caregiver?.relationship || '',
        moment(patient?.startDate).format('MM/DD/YYYY') || '',
        diagnoses,
        (practitioner?.firstName || '') + ' ' + (practitioner?.lastName || ''),
      ]);
    }
    return _exportPatients;
  };
  const upload = async (data: any[]) => {
    await database.write(async () => {
      for (const p of data) {
        const newlyCreatedPatient = await database
          ?.get(Patient.table)
          .create(patient => {
            patient.partition = selectedGroup;
            patient.identifier = p.identifier;
            patient.firstName = p.firstName;
            patient.lastName = p.lastName;
            patient.gender = p.gender;
            patient.birthDate = p.birthDate;
            patient.startDate = p.startDate;
            patient.diagnoses = [p.diagnoses];
            patient.address = {
              use: p.use,
              line: p.line,
              city: p.city,
              state: p.state,
              postalCode: p.postalCode,
            };
            patient.staff.id = p.staff;
            patient.state = 'active';
          });
        await database.get(Caregiver.table).create(caregiver => {
          caregiver.partition = selectedGroup;
          caregiver.firstName = p.caregiverFirstName;
          caregiver.lastName = p.caregiverLastName;
          caregiver.relationship = p.caregiverRelationship;
          caregiver.mobilePhone = p.caregiverMobilePhone;
          caregiver.email = p.caregiverEmail;
          caregiver.patient.id = newlyCreatedPatient.id;
        });
      }
      setCreatedPatients(data);
    });
  };

  const navigateToUpsert = () => {
    navigation.navigate('PatientFormScreen');
  };
  const viewCallback = (id: string) => {
    navigation.navigate('PatientProfile', {
      patientId: id,
    });
  };
  const editCallback = (id: string) => {
    navigation.navigate('PatientFormScreen', {
      id: id,
    });
  };
  const deleteCallback = (patient: any) => {
    setShowDelete(patient);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = (patient: any) => {
    patient.updateEntity({
      state: 'inactive',
      deletedAt: new Date(),
      deletedBy: profile?.id,
    });
    setShowDelete(false);
  };
  const archiveCallback = (patient: any) => {
    if (patient?.state !== 'archived') {
      patient.updateEntity({state: 'archived'});
    } else {
      patient.updateEntity({state: 'active'});
    }
  };

  return (
    <BaseIndexScreen
      title={'Clients'}
      canAdd={role?.patientCreate}
      add={navigateToUpsert}
      search={
        <Searchbar
          value={searchFilter}
          onChangeText={setSearchFilter}
          placeholder={'Search'}
        />
      }
      exportBut={<CsvExport data={exportPatients} name={'client_export'} />}
      importBut={
        <DromoImportModal
          fields={Import(users)}
          rowHooks={RowHooks}
          onSubmit={upload}
          identifier={'Clients'}
          templateDownloadFilename={'client_import'}
        />
      }
      filters={
        <ClientFilterMenu
          applyFilters={setFilters}
          filter={filters}
          tags={tags}
        />
      }
      snackbar={
        <Snackbar
          visible={createdPatients.length > 0}
          onDismiss={() => setCreatedPatients([])}
          duration={99999999999999999}
          action={{
            label: 'Dismiss',
            onPress: () => {},
          }}>
          {createdPatients.length} Client(s) Created
        </Snackbar>
      }>
      <ClientFilterChip filters={filters} setFilters={setFilters} tags={tags} />
      <DeleteModal
        model={'Client'}
        text={
          showDelete ? `${showDelete?.firstName} ${showDelete?.lastName}` : null
        }
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <PatientList
        search={searchFilter}
        viewCallback={viewCallback}
        canEdit={role?.patientEdit}
        editCallback={editCallback}
        canDelete={role?.patientDelete}
        deleteCallback={deleteCallback}
        profile={profile}
        role={role}
        canArchive={role?.patientEdit}
        archiveCallback={archiveCallback}
        filter={filters}
      />
    </BaseIndexScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({profile, database, authentication, role}: any) => ({
    patients: role?.patientAssignedOnly
      ? database
          .get('patients')
          .query(
            Q.where('deleted_at', null),
            Q.where('_partition', authentication.selectedGroup),
          )
      : profile.patients,
    users: database
      .get('users')
      .query(
        Q.where('deleted_at', null),
        Q.where('state', 'active'),
        Q.where('_partition', authentication.selectedGroup),
      )
      .observe(),
    tags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.where('tag_type', Q.eq('Related Services')),
      ),
  })),
)(PatientListScreen);
