import React, {forwardRef} from 'react';
import SignatureInput from 'src/hook-form/signature-input';
import {Controller, useFormContext} from 'react-hook-form';

interface Props {
  name: string;
  label: string;
  defaultValue?: string;
  rules?: any;
  helper?: string;
}

const HookFormSignatureInput = forwardRef((props: Props) => {
  const {control} = useFormContext();

  return (
    <Controller
      control={control}
      rules={props.rules}
      render={({field, fieldState: {error}}) => (
        <SignatureInput ref={field.ref} {...props} {...field} error={error} />
      )}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
});

export default HookFormSignatureInput;
