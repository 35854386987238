import React, {forwardRef} from 'react';
import {View} from 'react-native';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import RadioInput from 'src/hook-form/radio-input';
import Staff from 'src/hook-form-inputs/staff';
import Caregivers from 'src/hook-form-inputs/caregivers';
import Name from 'src/hook-form-inputs/name';
import SignatureInput from 'src/hook-form-wrapper/signature-input';

const signatoryArray = [
  {label: 'Staff', value: 'staff'},
  {label: 'Caregiver', value: 'caregiver'},
  {label: 'Other', value: 'other'},
];

const SignatureForm = forwardRef(({index, patient}: any) => {
  const methods = useFormContext();

  const type = useWatch({
    control: methods.control,
    name: 'type',
  });

  return (
    <View>
      {index !== 0 ? (
        <Controller
          control={methods.control}
          render={({field: {onChange, value}}) => (
            <RadioInput
              items={signatoryArray}
              value={value}
              onChange={onChange}
            />
          )}
          name={'type'}
          defaultValue={'other'}
        />
      ) : null}
      {type ? (
        type === 'staff' ? (
          <Staff override={'signatory'} multiple={false} />
        ) : type === 'caregiver' ? (
          <Caregivers
            override={'signatory'}
            multiple={false}
            patient={patient}
          />
        ) : type === 'other' ? (
          <Name override={'signatory'} />
        ) : null
      ) : null}
      <SignatureInput name={'signature'} label={''} />
    </View>
  );
});

export default SignatureForm;
