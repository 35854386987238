import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLegend,
} from 'victory-native';
import {boxShadow} from 'src/styles/mixins';
import {Colors, Typography} from 'src/styles';
import moment from 'moment';
import React, {useState} from 'react';
import {View, Text, Platform} from 'react-native';
import _ from 'lodash';
import {G, ForeignObject} from 'react-native-svg';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import EStyleSheet from 'react-native-extended-stylesheet';
import SwitchInput from 'src/hook-form/switch-input';
import {Separator} from 'src/common-components/atoms';
import ViewShot from 'react-native-view-shot';
import {HTMLFlyOut as EnvHTMLFlyout} from 'src/modules/reports/components/environmental-factor-items';
import {useDimensions} from '@react-native-community/hooks';

const isWeb = Platform.OS === 'web';

const stateSymbol = (state: string) => {
  let symbol;
  switch (state) {
    case 'baseline':
      symbol = 'circle';
      break;
    case 'in-treatment':
      symbol = 'diamond';
      break;
    case 'in-maintenance':
      symbol = 'triangleUp';
      break;
    case 'completed':
      symbol = 'square';
      break;
  }
  return symbol;
};

const stateColor = (state: string) => {
  let color;
  switch (state) {
    case 'baseline':
      color = Colors.SECONDARY_600;
      break;
    case 'in-treatment':
      color = Colors.SECONDARY_800;
      break;
    case 'in-maintenance':
      color = Colors.VIOLET_500;
      break;
    case 'completed':
      color = Colors.LIME_700;
      break;
  }
  return color;
};
const stateText = (state: string) => {
  let text;
  switch (state) {
    case 'baseline':
      text = 'Baseline';
      break;
    case 'in-treatment':
      text = 'In Treatment';
      break;
    case 'in-maintenance':
      text = 'Maintenance';
      break;
    case 'completed':
      text = 'Mastered';
      break;
  }
  return text;
};
const HTMLFlyOut = ({x, y, datum, state, styles, method}: any) => {
  return (
    <G>
      <ForeignObject
        x={x - 70}
        y={y / 2}
        width="140"
        height="600"
        /* eslint-disable-next-line react-native/no-inline-styles */
        style={{overflow: 'visible'}}>
        <View style={styles.card}>
          <Text style={styles.captionMediumTxt}>Date</Text>
          <Text style={styles.captionTxt}>
            {moment(datum.date).format('MM/DD/YY')}
          </Text>

          {datum?.collectors && datum?.collectors.length > 0 && (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Collector
              </Text>
              {datum?.collectors.map((collector: string, index: number) => (
                <Text
                  key={`collector-${collector}-${index}`}
                  style={styles.captionTxt}>
                  {collector}
                </Text>
              ))}
            </>
          )}

          {method === 'task_analysis' || method === 'trial_by_trial' ? (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Percent Correct
              </Text>
              <Text style={styles.captionTxt}>{Math.ceil(datum.y)}%</Text>
            </>
          ) : method === 'duration' && datum.duration !== '' ? (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Total Duration
              </Text>
              <Text style={styles.captionTxt}>{datum.duration}</Text>
            </>
          ) : method === 'rate' ? (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>Rate</Text>
              <Text style={styles.captionTxt}>{datum.y.toFixed(2)}</Text>
            </>
          ) : method === 'frequency' ? (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Total Instances
              </Text>
              <Text style={styles.captionTxt}>{datum.y}</Text>
            </>
          ) : method === 'interval' ? (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Behavior % Responding
              </Text>
              <Text style={styles.captionTxt}>{Math.ceil(datum.y)}%</Text>
            </>
          ) : (
            <></>
          )}

          {datum.target && (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Target
              </Text>
              <Text style={styles.captionTxt}>{datum.target}</Text>
            </>
          )}

          <Text style={[styles.captionMediumTxt, styles.mt_10]}>Status</Text>
          <Text style={styles.captionTxt}>{stateText(state)}</Text>
        </View>
      </ForeignObject>
    </G>
  );
};

const Graph = ({
  slot = <></>,
  yAxisLabel = '',
  data = [],
  program = {},
  phaseChanges = [],
  lines = [],
  initialType = 'line',
  maxY = 100,
  title = null,
  extras = {},
}: any) => {
  const [showEnvFactor, setShowEnvFactor] = useState(true);
  const [showEnvChange, setShowEnvChange] = useState(true);
  const [graph, setGraph] = useState(initialType);
  const styles = getStyles();
  const dimensions = useDimensions();

  let realY = maxY !== 0 ? maxY : 1;
  const domain = {x: [0, (data.length || 1) + 1], y: [0, realY]};

  return (
    <>
      <View
        style={[
          styles.paddingMVertical,
          styles.flexRow,
          styles.alignItemCenter,
          styles.justifySpaceBetween,
        ]}>
        {isWeb ? (
          <View
            style={[styles.flexRow, styles.column450, styles.alignItemCenter]}>
            <SwitchInput
              label={''}
              value={showEnvChange}
              onChange={val => setShowEnvChange(val)}
              showHelper={false}
              color={Colors.TEAL_600}
            />
            <Text style={[styles.legendText]}>Environmental Changes</Text>
            <Separator width={10} />
            <SwitchInput
              label={''}
              value={showEnvFactor}
              onChange={val => setShowEnvFactor(val)}
              showHelper={false}
              color={Colors.ORANGE_300}
            />
            <Text style={[styles.legendText]}>Environmental Factors</Text>
          </View>
        ) : null}
        {isWeb ? (
          <View style={[styles.flexRow, styles.column450]}>
            <MaterialIcon
              name="linear-scale"
              size={33}
              onPress={() => setGraph('line')}
              color={graph === 'line' ? Colors.RAVEN_BLACK : '#DEDEE1'}
              style={[styles.mr_10]}
            />
            <Ionicons
              name={'bar-chart-outline'}
              size={24}
              color={graph === 'bar' ? Colors.RAVEN_BLACK : '#DEDEE1'}
              onPress={() => setGraph('bar')}
            />
          </View>
        ) : null}
      </View>
      <View style={styles.flexRow}>{slot}</View>
      <ViewShot ref={extras?.graphExportRef || null}>
        {title ? title : null}
        <VictoryChart
          theme={VictoryTheme.material}
          width={dimensions.screen.width * 0.95}
          domain={domain}
          domainPadding={{x: 40, y: 30}}
          scale={{x: 'time'}}
          containerComponent={<VictoryVoronoiContainer />}>
          <VictoryLegend
            x={10}
            y={0}
            orientation="horizontal"
            gutter={20}
            titleComponent={<></>}
            style={{border: {stroke: 'black', strokeWidth: 0.1}}}
            data={[
              {
                name: 'Environmental Change',
                symbol: {fill: Colors.TEAL_600, type: 'minus'},
                labels: {fontSize: isWeb ? 10 : 9},
              },
              {
                name: 'Environmental Factor',
                symbol: {fill: Colors.ORANGE_300, type: 'minus'},
                labels: {fontSize: isWeb ? 10 : 9},
              },
            ]}
          />
          <VictoryLegend
            x={10}
            y={25}
            orientation="horizontal"
            gutter={20}
            titleComponent={<></>}
            style={{border: {stroke: 'black', strokeWidth: 0.1}}}
            data={[
              {
                name: 'Baseline',
                symbol: {fill: Colors.SECONDARY_600, type: 'circle'},
                labels: {fontSize: isWeb ? 10 : 9},
              },
              {
                name: 'In Treatment',
                symbol: {fill: Colors.SECONDARY_800, type: 'diamond'},
                labels: {fontSize: isWeb ? 10 : 9},
              },
              {
                name: 'Maintenance',
                symbol: {fill: Colors.VIOLET_500, type: 'triangleUp'},
                labels: {fontSize: isWeb ? 10 : 9},
              },
              {
                name: 'Mastered',
                symbol: {fill: Colors.LIME_700, type: 'square'},
                labels: {fontSize: isWeb ? 10 : 9},
              },
            ]}
          />
          {program?.method === 'interval' ||
          program?.method === 'trial_by_trial' ||
          program?.method === 'task_analysis' ? (
            <VictoryAxis
              domain={{y: [0, 100]}}
              domainPadding={{x: [10, -10], y: 5}}
              tickCount={10}
              tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              dependentAxis
              label={yAxisLabel}
              style={{
                axis: {stroke: 'transparent'},
              }}
              tickFormat={x => `${x}%`}
              axisLabelComponent={
                <VictoryLabel
                  style={{...Typography.CAPTION, fill: Colors.TEXT_PRIMARY}}
                  x={10}
                />
              }
            />
          ) : program?.method === 'duration' ? (
            <VictoryAxis
              dependentAxis
              label={yAxisLabel}
              style={{
                axis: {stroke: 'transparent'},
              }}
              axisLabelComponent={
                <VictoryLabel
                  style={{...Typography.CAPTION, fill: Colors.TEXT_PRIMARY}}
                  x={10}
                />
              }
            />
          ) : program?.method === 'frequency' ? (
            <VictoryAxis
              dependentAxis
              label={yAxisLabel}
              tickValues={_.range(1, realY + 2)}
              fixLabelOverlap={true}
              tickFormat={x => {
                return Math.round(x);
              }}
              style={{
                tickLabels: {...Typography.CAPTION, color: Colors.TEXT_PRIMARY},
                axis: {stroke: 'transparent'},
              }}
              axisLabelComponent={
                <VictoryLabel
                  style={{...Typography.CAPTION, color: Colors.TEXT_PRIMARY}}
                  x={15}
                />
              }
            />
          ) : (
            <VictoryAxis
              dependentAxis
              label={yAxisLabel}
              style={{
                tickLabels: {...Typography.CAPTION, color: Colors.TEXT_PRIMARY},
                axis: {stroke: 'transparent'},
              }}
              axisLabelComponent={
                <VictoryLabel
                  style={{
                    ...Typography.CAPTION,
                    color: Colors.TEXT_PRIMARY,
                  }}
                  x={10}
                />
              }
            />
          )}
          <VictoryAxis
            style={{
              axis: {stroke: Colors.GRAY_100},
              tickLabels: {
                ...Typography.CAPTION,
                angle: 45,
                fill: Colors.PRIMARY_600,
              },
              grid: {stroke: 'transparent'},
            }}
            label={'Session Date'}
            axisLabelComponent={
              <VictoryLabel
                style={{...Typography.CAPTION, color: Colors.TEXT_PRIMARY}}
                dy={30}
              />
            }
            tickCount={10}
            tickFormat={x => {
              return moment(data[x - 1]?.startTimestamp).format('MMM DD');
            }}
            tickValues={_.range(1, data.length + 1)}
            tickLabelComponent={
              <VictoryLabel
                style={{...Typography.CAPTION, fill: Colors.TEXT_PRIMARY}}
              />
            }
          />

          {phaseChanges.map((point: any, index: number) => {
            if (point.state) {
              return (
                <VictoryLine
                  key={`phase-changes-${index}`}
                  style={{
                    data: {
                      stroke: stateColor(point.state),
                      strokeWidth: 0.75,
                    },
                    labels: {
                      fill: stateColor(point.state),
                      fontSize: 14,
                    },
                  }}
                  data={[
                    {x: point.line - 0.5, y: 0},
                    {x: point.line - 0.5, y: realY},
                  ]}
                />
              );
            }
          })}

          {phaseChanges.map((point: any, index: number) => {
            if (point.state) {
              return (
                <VictoryLabel
                  key={`phase-changes-label-${index}`}
                  text={stateText(point.state)}
                  /* eslint-disable-next-line react-native/no-inline-styles */
                  style={{
                    ...Typography.CAPTION,
                    color: stateColor(point.state),
                    angle: -90,
                  }}
                  datum={{x: point.line - 0.55, y: realY / 2}}
                  textAnchor={'middle'}
                />
              );
            }
          })}

          {graph === 'bar' ? (
            lines.map((line: any, index: number) => {
              if (line.type === 'point') {
                return (
                  <VictoryBar
                    data={line.points}
                    style={{
                      data: {fill: stateColor(line.state)},
                    }}
                    barWidth={30}
                    labels={() => ' '}
                    labelComponent={
                      <VictoryTooltip
                        key={`point-tooltip-${index}`}
                        renderInPortal={false}
                        flyoutComponent={
                          <HTMLFlyOut
                            state={line.state}
                            styles={styles}
                            method={program?.method}
                          />
                        }
                      />
                    }
                  />
                );
              } else if (line.type === 'change' && showEnvChange) {
                return (
                  <VictoryLine
                    key={`env-${index}`}
                    style={{
                      data: {
                        stroke: Colors.TEAL_600,
                        strokeWidth: 0.5,
                        strokeDasharray: '4, 2',
                      },
                      labels: {
                        fill: Colors.RAVEN_BLACK,
                        fontSize: 10,
                      },
                    }}
                    labels={() => ''}
                    labelComponent={
                      <VictoryTooltip
                        key={`env-tooltip-${index}`}
                        constrainToVisibleArea={true}
                        renderInPortal={false}
                        flyoutComponent={<EnvHTMLFlyout point={line} />}
                      />
                    }
                    data={[
                      {x: line.x, y: 0},
                      {x: line.x, y: realY},
                    ]}
                  />
                );
              } else if (line.type === 'factor' && showEnvFactor) {
                return (
                  <VictoryLine
                    key={`env-${index}`}
                    style={{
                      data: {
                        stroke: Colors.ORANGE_300,
                        strokeWidth: 0.5,
                        strokeDasharray: '4, 2',
                      },
                      labels: {
                        fill: Colors.RAVEN_BLACK,
                        fontSize: 10,
                      },
                    }}
                    labels={() => ' '}
                    labelComponent={
                      <VictoryTooltip
                        key={`env-tooltip-${index}`}
                        constrainToVisibleArea={true}
                        renderInPortal={false}
                        flyoutComponent={<EnvHTMLFlyout point={line} />}
                      />
                    }
                    data={[
                      {x: line.x, y: 0},
                      {x: line.x, y: realY},
                    ]}
                  />
                );
              }
            })
          ) : (
            <></>
          )}

          {graph === 'line' ? (
            lines.map((line: any, index: number) => {
              if (line.type === 'point') {
                return (
                  <VictoryLine
                    key={`line-${index}`}
                    data={line.points}
                    style={{
                      data: {stroke: stateColor(line.state), strokeWidth: 2},
                    }}
                  />
                );
              } else if (line.type === 'change' && showEnvChange) {
                return (
                  <VictoryLine
                    key={`env-${index}`}
                    style={{
                      data: {
                        stroke: Colors.TEAL_600,
                        strokeWidth: 0.5,
                        strokeDasharray: '4, 2',
                      },
                      labels: {
                        fill: Colors.RAVEN_BLACK,
                        fontSize: 10,
                      },
                    }}
                    labels={() => ''}
                    labelComponent={
                      <VictoryTooltip
                        key={`env-tooltip-${index}`}
                        constrainToVisibleArea={true}
                        renderInPortal={false}
                        flyoutComponent={<EnvHTMLFlyout point={line} />}
                      />
                    }
                    data={[
                      {x: line.x, y: 0},
                      {x: line.x, y: realY},
                    ]}
                  />
                );
              } else if (line.type === 'factor' && showEnvFactor) {
                return (
                  <VictoryLine
                    key={`env-${index}`}
                    style={{
                      data: {
                        stroke: Colors.ORANGE_300,
                        strokeWidth: 0.5,
                        strokeDasharray: '4, 2',
                      },
                      labels: {
                        fill: Colors.RAVEN_BLACK,
                        fontSize: 10,
                      },
                    }}
                    labels={() => ' '}
                    labelComponent={
                      <VictoryTooltip
                        key={`env-tooltip-${index}`}
                        constrainToVisibleArea={true}
                        renderInPortal={false}
                        flyoutComponent={<EnvHTMLFlyout point={line} />}
                      />
                    }
                    data={[
                      {x: line.x, y: 0},
                      {x: line.x, y: realY},
                    ]}
                  />
                );
              } else {
                return <></>;
              }
            })
          ) : (
            <></>
          )}

          {graph === 'line' ? (
            lines.map((line: any, index: number) => {
              if (line.type === 'point') {
                return (
                  <VictoryScatter
                    key={`scatter-${index}`}
                    data={line.points}
                    symbol={stateSymbol(line.state)}
                    style={{
                      data: {
                        stroke: stateColor(line.state),
                        strokeWidth: 3,
                        fill: stateColor(line.state),
                      },
                    }}
                    labels={() => ' '}
                    labelComponent={
                      <VictoryTooltip
                        key={`point-tooltip-${index}`}
                        renderInPortal={false}
                        constrainToVisibleArea={true}
                        flyoutComponent={
                          <HTMLFlyOut
                            state={line.state}
                            styles={styles}
                            method={program?.method}
                          />
                        }
                      />
                    }
                  />
                );
              }
            })
          ) : (
            <></>
          )}
        </VictoryChart>
      </ViewShot>
    </>
  );
};

const getStyles = () =>
  EStyleSheet.create({
    pt_20: {
      paddingTop: 20,
    },
    mt_10: {
      marginTop: 10,
    },
    mt_20: {
      marginTop: 20,
    },
    flexRow: {
      flexDirection: 'row',
    },
    alignItemCenter: {
      alignItems: 'center',
    },
    justifySpaceBetween: {
      justifyContent: 'space-between',
    },
    backgroundBlack: {
      backgroundColor: Colors.RAVEN_BLACK,
    },
    backgroundTransparent: {
      backgroundColor: 'transparent',
    },
    paddingMVertical: {
      paddingVertical: 8,
    },
    mr_10: {
      marginRight: 20,
    },
    legendDot: {
      width: 12,
      height: 12,
      borderRadius: 1,
      marginRight: 7,
    },
    legendText: {
      ...Typography.CAPTION_MEDIUM,
      color: '#25262F',
    },
    bgArctic: {
      backgroundColor: Colors.ARCTIC_500,
    },
    bgOrange: {
      backgroundColor: Colors.ORANGE,
    },
    card: {
      borderRadius: 20,
      backgroundColor: Colors.RAVEN_WHITE,
      padding: 15,
      ...boxShadow(Colors.RAVEN_BLACK),
    },
    captionTxt: {
      ...Typography.CAPTION,
      color: '#25262F',
    },
    captionMediumTxt: {
      ...Typography.CAPTION_MEDIUM,
      color: '#25262F',
    },
    alignCenter: {
      alignItems: 'center',
    },
    alignSelfCenter: {
      alignSelf: 'center',
    },
    '@media (max-width: 850px)': {
      column450: {
        flexDirection: 'column',
      },
    },
  });

export default Graph;
