import React, {useState} from 'react';
import {Searchbar, Snackbar} from 'react-native-paper';
import _ from 'lodash';
import {BaseIndexScreen, DeleteModal} from 'src/design-system';
import DromoImportModal from 'src/common-components/bulk-import';
import CsvExport from 'src/common-components/csv-export/components';
import {positionsList} from 'src/form-inputs/position-input';
import moment from 'moment';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useNavigation} from '@react-navigation/core';
import {Credential, User} from 'src/models';
import UserList from 'src/modules/users/components/user-list';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {useSelector} from 'react-redux';
import {of} from 'rxjs';
import StaffFilterMenu from 'src/modules/users/components/staff-filter';
import StaffFilterChip from 'src/modules/users/components/staff-filter-chip';
import {IDeveloperField, IRowHook} from 'dromo-uploader-react';
import {MaskService} from 'react-native-masked-text';
import {
  BirthDate,
  Country,
  City,
  Credential as CredentialInput,
  Email,
  FirstName,
  Gender,
  LastName,
  Modifier,
  PhoneNumber,
  Position,
  PostalCode,
  State,
  Permission,
} from 'src/hook-form-inputs';

export const Import: (...args: any) => IDeveloperField[] = (roles: any[]) => [
  FirstName.Import('firstName'),
  LastName.Import('lastName'),
  Email.Import('email'),
  Gender.Import('gender'),
  BirthDate.Import('birthDate'),
  Permission.Import('permission', roles),
  Position.Import('position'),
  PhoneNumber.Import('mobilePhone'),
  Country.Import('country'),
  City.Import('city'),
  State.Import('state'),
  PostalCode.Import('postalCode'),
  CredentialInput.Import('credential'),
  Modifier.Import('modifier'),
];

export const RowHooks: IRowHook[] = [
  record => {
    const newRecord = record;

    newRecord.row.birthDate.value = MaskService.toMask(
      'datetime',
      newRecord.row.birthDate.value,
      {
        format: 'MM/DD/YYYY',
      },
    );

    newRecord.row.mobilePhone.value = MaskService.toMask(
      'custom',
      newRecord.row.mobilePhone.value,
      {
        mask: '(999) 999-9999',
      },
    );

    return newRecord;
  },
];

const UserListScreen = ({users, role, roles, profile, tags}: any) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [showDelete, setShowDelete] = useState<string | boolean>(false);
  const [createdPractitioners, setCreatedPractitioners] = useState<any[]>([]);
  const navigation = useNavigation();
  const database = useDatabase();
  const [filters, setFilters] = useState<any>({
    staffState: [],
    relatedServices: [],
  });

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const exportPractitioners = async () => {
    let practitioners = [
      [
        'First Name',
        'Last Name',
        'Email',
        'Gender',
        'Birth Date',
        'Permission',
        'Position',
        'Phone Number',
        'Address Type',
        'Street Address',
        'City',
        'State',
        'Postal Code',
      ],
    ];
    users?.map((practitioner: any) => {
      const positionLabel = positionsList.find(
        (position: any) => position.value === practitioner.position,
      )?.label;
      practitioners.push([
        practitioner?.firstName || '',
        practitioner?.lastName || '',
        practitioner?.email || '',
        _.capitalize(practitioner?.gender) || '',
        moment(practitioner?.birthDate).format('MM/DD/YYYY') || '',
        practitioner?.permission || '',
        positionLabel || '',
        practitioner?.mobilePhone || '',
        _.capitalize(practitioner?.address?.use) || '',
        practitioner?.address?.line?.[0] || '',
        practitioner?.address?.city || '',
        practitioner?.address?.state || '',
        practitioner?.address?.postalCode || '',
      ]);
    });
    return practitioners;
  };
  const upload = async (data: any[]) => {
    for (const u of data) {
      await database?.write(async () => {
        const createdUser = await database?.get(User.table).create(entity => {
          entity.partition = selectedGroup;
          entity.identifier = '';
          entity.firstName = u.firstName;
          entity.lastName = u.lastName;
          entity.email = u.email.toLowerCase();
          entity.gender = u.gender;
          entity.birthDate = u.birthDate;
          entity.role.id = u.permission;
          entity.position = u.position;
          entity.mobilePhone = u.mobilePhone;
          entity.state = 'active';
          entity.address = {
            use: u.use,
            line: u.line,
            city: u.city,
            state: u.state,
            postalCode: u.postalCode,
          };
          entity.credentials = [u.credential];
          entity.modifier = u.modifier;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });

        await database?.get(Credential.table).create(entity => {
          entity.partition = selectedGroup;
          entity.userId = createdUser.id;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });

        return createdUser;
      });
    }
    setCreatedPractitioners(data);
  };
  const navigateToUpsert = () => {
    navigation.navigate('UserFormScreen');
  };
  const viewCallback = (id: string) => {
    navigation.navigate('UserProfile', {
      practitionerId: id,
    });
  };
  const editCallback = (id: string) => {
    navigation.navigate('UserFormScreen', {
      id: id,
    });
  };
  const deleteCallback = (practitioner: any) => {
    setShowDelete(practitioner);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = (practitioner: any) => {
    practitioner.updateEntity({
      state: 'inactive',
      deletedAt: new Date(),
      deletedBy: profile?.id,
    });
    setShowDelete(false);
  };
  const archiveCallback = (practitioner: any) => {
    if (practitioner?.state !== 'archived') {
      practitioner.updateEntity({
        state: 'archived',
        deletedAt: new Date(),
        deletedBy: profile?.id,
      });
    } else {
      practitioner.updateEntity({
        state: 'active',
        deletedAt: null,
        deletedBy: null,
      });
    }
  };

  return (
    <BaseIndexScreen
      title={'Staff'}
      canAdd={role?.userCreate}
      add={navigateToUpsert}
      search={
        <Searchbar
          value={searchFilter}
          onChangeText={value => setSearchFilter(value)}
          placeholder={'Search'}
        />
      }
      exportBut={<CsvExport data={exportPractitioners} name={'staff_export'} />}
      importBut={
        <DromoImportModal
          fields={Import(roles)}
          rowHooks={RowHooks}
          onSubmit={upload}
          identifier={'Staff'}
          templateDownloadFilename={'staff_import'}
        />
      }
      filters={
        <StaffFilterMenu
          applyFilters={setFilters}
          filter={filters}
          tags={tags}
        />
      }
      snackbar={
        <Snackbar
          visible={createdPractitioners.length > 0}
          onDismiss={() => setCreatedPractitioners([])}
          duration={99999999999999999}
          action={{
            label: 'Dismiss',
            onPress: () => {},
          }}>
          {createdPractitioners.length} Staff Member(s) Created
        </Snackbar>
      }>
      <StaffFilterChip filters={filters} setFilters={setFilters} tags={tags} />
      <DeleteModal
        model={'Staff'}
        text={
          showDelete ? `${showDelete?.firstName} ${showDelete?.lastName}` : null
        }
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <UserList
        search={searchFilter}
        viewCallback={viewCallback}
        canEdit={role?.userEdit}
        editCallback={editCallback}
        canDelete={role?.userDelete}
        deleteCallback={deleteCallback}
        canArchive={role?.userEdit}
        archiveCallback={archiveCallback}
        filter={filters}
      />
    </BaseIndexScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(['authentication'], ({database, authentication}: any) => ({
    users: database
      .get('users')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
      )
      .observe(),
    roles: database
      .get('roles')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
      ),
    tags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.where('tag_type', Q.eq('Related Services')),
      ),
  })),
)(UserListScreen);
