export const exportCsv = async (name: string, content: string[][]) => {
  let csv = '';

  content.forEach(function (row) {
    csv += row
      .map(String)
      .map(v => v.replaceAll('"', '""'))
      .map(v => `"${v}"`)
      .join(',');
    csv += '\n';
  });
  const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  // @ts-ignore
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', name + '.csv');
  // @ts-ignore
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "my_data.csv".
};
