import React from 'react';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {Q} from '@nozbe/watermelondb';
import {CardList} from 'src/design-system';
import {PatientCard} from 'src/modules/patients/components';
import withState from 'src/redux/wrapper';

const PatientList = ({
  patients,
  viewCallback,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
  canArchive,
  archiveCallback,
}: any) => {
  return (
    <CardList
      items={patients}
      loading={false}
      emptyIcon={'account-supervisor'}
      emptyMessage={'No result for clients found'}
      renderItem={({item}) => (
        <PatientCard
          patient={item}
          viewCallback={() => viewCallback(item.id)}
          canEdit={canEdit}
          editCallback={() => editCallback(item.id)}
          canDelete={canDelete}
          canArchive={canArchive}
          archiveCallback={() => archiveCallback(item)}
          deleteCallback={() => deleteCallback(item)}
        />
      )}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['search', 'filter'],
    ({profile, search, database, authentication, role, filter}: any) => {
      const queries = [];
      if (filter?.patientState && filter?.patientState.includes('archived')) {
        queries.push(Q.where('state', 'archived'));
      } else {
        queries.push(Q.where('state', Q.notEq('archived')));
      }
      const tagIds = [...filter.relatedServices];

      return {
        patients: search
          ? !role?.patientAssignedOnly
            ? database
                .get('patients')
                .query(
                  ...(tagIds.length
                    ? [Q.on('taggables', Q.where('tag_id', Q.oneOf(tagIds)))]
                    : []),
                  Q.or(
                    Q.where(
                      'first_name',
                      Q.like(`${Q.sanitizeLikeString(search)}%`),
                    ),
                    Q.where(
                      'last_name',
                      Q.like(`${Q.sanitizeLikeString(search)}%`),
                    ),
                  ),
                  ...queries,
                  Q.where('deleted_at', null),
                  Q.where('_partition', authentication.selectedGroup),
                )
            : database
                .get('patients')
                .query(
                  ...(tagIds.length
                    ? [Q.on('taggables', Q.where('tag_id', Q.oneOf(tagIds)))]
                    : []),
                  Q.on(
                    'care_team_participants',
                    Q.and(
                      Q.where('user_id', Q.eq(profile.id)),
                      Q.where('deleted_at', null),
                    ),
                  ),
                  Q.or(
                    Q.where(
                      'first_name',
                      Q.like(`${Q.sanitizeLikeString(search)}%`),
                    ),
                    Q.where(
                      'last_name',
                      Q.like(`${Q.sanitizeLikeString(search)}%`),
                    ),
                  ),
                  ...queries,
                  Q.where('deleted_at', null),
                  Q.where('_partition', authentication.selectedGroup),
                )
          : !role?.patientAssignedOnly
          ? database
              .get('patients')
              .query(
                ...(tagIds.length
                  ? [Q.on('taggables', Q.where('tag_id', Q.oneOf(tagIds)))]
                  : []),
                ...queries,
                Q.where('deleted_at', null),
                Q.where('_partition', authentication.selectedGroup),
              )
          : database
              .get('patients')
              .query(
                ...(tagIds.length
                  ? [Q.on('taggables', Q.where('tag_id', Q.oneOf(tagIds)))]
                  : []),
                Q.on(
                  'care_team_participants',
                  Q.and(
                    Q.where('user_id', Q.eq(profile.id)),
                    Q.where('deleted_at', null),
                  ),
                ),
                ...queries,
                Q.where('deleted_at', null),
                Q.where('_partition', authentication.selectedGroup),
              ),
      };
    },
  ),
)(PatientList);
